import Button from "../views/Button";
import Modal from "./Modal";
import "./ConfirmModal.css";

const ConfirmModal = ({
  title,
  text,
  successText = "Conferma",
  cancelText = "Annulla",
  colorCancel = "secondary",
  onSuccess,
  onCancel,

  children,
}) => {
  return (
    <Modal>
      <div className="modal-container">
        <section className="confirm-modal">
          <h3>{title}</h3>

          <p>{text}</p>

          {children}

          <div className="modal-btns">
            <Button onclick={() => onSuccess()} text={successText} />
            <Button
              onclick={() => onCancel()}
              text={cancelText}
              color={colorCancel}
            />
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
