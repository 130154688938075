import { useRef, useEffect, useCallback } from "react";
import "./DropDown.css";

const DropDown = ({ name, options, onchange, hint, text }) => {
  const selectRef = useRef();
  const renderOptions = useCallback(() => {
    return options.map((element, index) => {
      return (
        <option value={element.value} key={index}>
          {element.text}
        </option>
      );
    });
  }, [options]);

  useEffect(() => {
    const changeEvent = new Event("change", { bubbles: true });
    selectRef.current.dispatchEvent(changeEvent);
  }, []);

  return (
    <div className="dropdown-container">
      <p className="label-fix" htmlFor={name}>
        {hint}
      </p>

      <select
        ref={selectRef}
        className="dropdown"
        id={name}
        name={name}
        value={text === null ? "" : text}
        onChange={(event) => {
          if (onchange !== undefined) {
            onchange(event);
          }
        }}
      >
        {renderOptions()}
      </select>
    </div>
  );
};

export default DropDown;
