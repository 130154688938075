import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import "./CalendarModalContainer.css";
import { useHistory } from "react-router-dom";
import { useForm, usePost } from "../Hooks";
import EventDetailModal from "./EventDetailModal";
import ConfirmModal from "./ConfirmModal";
import { BASE_URL } from "../Constants";
import Loader from "./Loader";
import DropDown from "../views/DropDown";
import TextInput from "../views/TextInput";
import CheckBox from "../views/CheckBox";

const deleteUrl = new URL(BASE_URL() + "/delete_booking/");
const setAccontoUrl = new URL(BASE_URL() + "/set_acconto/");
const detailUrl = new URL(BASE_URL() + "/get_event_detail/");
const toggleDaChiamareUrl = new URL(BASE_URL() + "/toggle_da_chiamare/");

const confirmModalTitle = "Sei sicuro di voler cancellare questa prenotazione";
const confirmModalText =
  "Se confermi tutti i dati relativi a questa prenotazione andranno persi";

const CalendarModalContainer = ({ showHandler, eventId, tipo }) => {
  const [detailFetchData, setDetailFetchData] = useState({
    url: null,
    query: null,
  });

  const [formValues, setFormValues, setFormValuesByEvent] = useForm({
    paymentMethod: "contanti",
    amount: 50,
    acconto_restituito: true,
  });

  const [Pdata, Ploading, Perror] = usePost(detailFetchData);

  const accontoTitle = useRef("");
  const accontoText = useRef("");

  const history = useHistory();

  const toggleDaChiamare = useCallback(() => {
    setDetailFetchData({
      url: toggleDaChiamareUrl,
      query: {
        id: eventId,
      },
    });
  }, [setDetailFetchData, eventId]);

  useEffect(() => {
    if (Pdata?.success != null) {
      showHandler("hide");
    }
    //setDetailFetchData({ url:null, query:null })
  }, [Pdata?.success, showHandler]);

  const showModalSelect = useMemo(() => {
    return (
      Pdata?.booking?.acconto_pagato === false ||
      Pdata?.evento?.acconto_pagato === false
    );
  }, [Pdata]);

  const setAccontoBtn = useCallback(() => {
    if (
      Pdata?.booking?.acconto_pagato === false ||
      Pdata?.evento?.acconto_pagato === false
    ) {
      accontoTitle.current =
        "Confermi la ricevuta dell' acconto per questo evento?";
      accontoText.current =
        "Se confermi verrà inviata un e-mail al cliente per informarlo dell' avvenuta ricezione del pagamento";
      return "Acconto ricevuto";
    } else {
      accontoTitle.current =
        "Vuoi restituire / annullare la ricezione dell' acconto per questo evento?";
      accontoText.current =
        "Se confermi verrà inviata un e-mail al cliente per informarlo della restituzione / annullamento dell' acconto";
      return "Restituisci / Rimuovi acconto";
    }
  }, [Pdata]);

  const updateBooking = useCallback(() => {
    if (tipo === "birthday")
      history.push("/birthday", { id: eventId, type: tipo });
    else if (tipo === "evento")
      history.push("/evento", { id: eventId, type: tipo });
  }, [tipo, history, eventId]);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAccontoConfirm, setShowAccontoConfirm] = useState(false);

  const deleteConfirmHandler = useCallback(
    (show) => {
      show ? setShowDeleteConfirm(true) : setShowDeleteConfirm(false);
    },
    [setShowDeleteConfirm],
  );

  const accontoConfirmHandler = useCallback(
    (show) => {
      show ? setShowAccontoConfirm(true) : setShowAccontoConfirm(false);
    },
    [setShowAccontoConfirm],
  );

  useEffect(() => {
    setDetailFetchData({
      url: `${detailUrl}${tipo}/`,
      query: { id: eventId },
    });
  }, []);

  const setDaChiamareBtn = useCallback(() => {
    if (tipo === "birthday") {
      if (Pdata && Pdata.booking?.da_chiamare === false)
        return 'Segna "da chiamare"';
      else if (Pdata && Pdata.booking?.da_chiamare === true)
        return 'Togli "da chiamare"';
    } else if (tipo === "evento") {
      if (Pdata && Pdata.evento?.da_chiamare === false)
        return 'Segna "da chiamare"';
      else if (Pdata && Pdata.evento?.da_chiamare === true)
        return 'Togli "da chiamare"';
    }
    return null;
  }, [Pdata, tipo]);

  const genPostBody = () => {
    const isPayed =
      Pdata?.booking?.acconto_pagato === true ||
      Pdata?.evento?.acconto_pagato === true;

    const formData = {
      id: eventId,
      ...formValues,
    };

    !isPayed && delete formData.acconto_restituito;
    return formData;
  };

  return (
    <>
      {Ploading === true || Pdata == null ? (
        <Loader />
      ) : (
        <EventDetailModal
          listValues={Pdata}
          showHandler={showHandler}
          btnAction={() => updateBooking()}
          showDeleteHandler={(show) => deleteConfirmHandler(show)}
          showAccontoHandler={(show) => accontoConfirmHandler(show)}
          btnText="Modifica"
          btn1Text={setAccontoBtn()}
          btn2Text="Elimina"
          btn3Text={setDaChiamareBtn()}
          toggleDaChiamare={() => toggleDaChiamare()}
        >
          {showDeleteConfirm && (
            <ConfirmModal
              onSuccess={() => {
                setDetailFetchData((old) => {
                  return {
                    ...old,
                    url: deleteUrl,
                    query: {
                      id: eventId,
                    },
                  };
                });
                //showHandler('hide')
              }}
              onCancel={() => showHandler("hide")}
              title={confirmModalTitle}
              text={confirmModalText}
            />
          )}

          {showAccontoConfirm && (
            <ConfirmModal
              onSuccess={() => {
                setDetailFetchData((old) => {
                  return {
                    url: setAccontoUrl,
                    query: genPostBody(),
                  };
                });
              }}
              onCancel={() => showHandler("hide")}
              title={accontoTitle.current}
              text={accontoText.current}
            >
              {showModalSelect ? (
                <div className="payment-select">
                  <DropDown
                    onchange={setFormValuesByEvent}
                    text={formValues.paymentMethod}
                    options={[
                      { text: "contanti", value: "CT" },
                      { text: "satispay", value: "SP" },
                      { text: "bonifico", value: "BN" },
                      { text: "pos", value: "PS" },
                    ]}
                    hint="metodo di pagamento"
                    name="paymentMethod"
                  />

                  <TextInput
                    onchange={setFormValuesByEvent}
                    name="amount"
                    hint="acconto"
                    type="number"
                    digit={true}
                    text={formValues.amount}
                  />
                </div>
              ) : (
                <div style={{ padding: "20px 10px" }}>
                  <CheckBox
                    checked={formValues.acconto_restituito}
                    text="Acconto restituito"
                    handler={() =>
                      setFormValues({
                        ...formValues,
                        acconto_restituito: !formValues.acconto_restituito,
                      })
                    }
                  />
                </div>
              )}
            </ConfirmModal>
          )}
        </EventDetailModal>
      )}
    </>
  );
};

export default CalendarModalContainer;
