import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Prenotazioni.css";
import { useForm, usePost, useGet } from "../Hooks";
import FormMask from "../sections/FormMask";
import NewBookingModalContainer from "../sections/NewBookingModalContainer";
import Button from "../views/Button";
import Loader from "../sections/Loader";
import AuthCheck from "../views/AuthCheck";
import {
  checkUndefArr,
  fixTypesQuery,
  showError,
  isDictUndef,
} from "../Methods";

import {
  BASE_URL,
  initialCustomerData,
  initialCakeData,
  initialParentData,
} from "../Constants";

import ConfirmModal from "../sections/ConfirmModal";

const getFormDataUrl = new URL(`${BASE_URL()}/get_form_data_birthday/`);
const createBookingUrl = new URL(BASE_URL() + "/create_booking/birthday/");
const updateBookingUrl = new URL(BASE_URL() + "/update_booking/");
const notifyUpdateUrl = new URL(BASE_URL() + "/notify_update/");

const getEventFormDataUrl = new URL(BASE_URL() + "/get_event_form_detail/");

const Prenotazioni = () => {
  const oldErrorRef = useRef();
  const baseInitalFormDataRef = useRef({
    aperitivo_genitori: [
      { text: "no", value: false },
      { text: "si", value: true },
    ],
    dolce: [
      { text: "no", value: false },
      { text: "si", value: true },
    ],
    animazione: [
      { text: "no", value: false },
      { text: "si", value: true },
    ],
    mangia_prima: [
      { text: "no", value: false },
      { text: "si", value: true },
    ],
  });

  const location = useLocation();
  const history = useHistory();

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCakeForm, setShowCakeForm] = useState(false);
  const [showParentsForm, setShowParentsForm] = useState(false);
  const [initialSelectCustomerData, setInitialSelectCustomerData] = useState(
    {},
  );
  const [initialSelectCakeData, setInitialSelectCakeData] = useState({});
  const [eventId, setEventId] = useState(null);
  const [postData, setPostData] = useState({});
  const [getData, setGetData] = useState({ url: getFormDataUrl });
  const [Pdata, Ploading, Perror] = usePost(postData);
  const [Gdata, Gloading, Gerror] = useGet(getData);

  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormNomiFesteggiati,
    setFormEtaFesteggiati,
  ] = useForm({ booking: {}, cake: {}, parents: {} });

  const notifyUpdateHandler = useCallback(() => {
    setPostData({ url: null, query: null });
    setPostData({ url: notifyUpdateUrl, query: { id: formValues.booking.id } });
  }, [setPostData, formValues]);

  const showModalHandler = useCallback(
    (action) => {
      if (action === "show") setShowDetailsModal(true);
      else if (action === "hide") setShowDetailsModal(false);
    },
    [setShowDetailsModal],
  );

  const collectFormData = useCallback(() => {
    let queryData = {};
    let localBookingValues = sessionStorage.getItem("booking");
    let localCakeValues = sessionStorage.getItem("cake");
    let localParentsValues = sessionStorage.getItem("parents");
    let strValuesList = [
      localBookingValues,
      localCakeValues,
      localParentsValues,
    ];

    if (strValuesList.length > 0 && checkUndefArr(strValuesList)) {
      for (let strval of strValuesList) {
        const dict = fixTypesQuery(strval);
        if (dict.dolce) {
          queryData["booking"] = dict;
        } else if (dict.cialda) {
          queryData["cake"] = dict;
        } else {
          queryData["parents"] = dict;
        }
      }
    }
    return queryData;
  }, []);

  const clearForm = useCallback(() => {
    setGetData({ url: null });
    sessionStorage.removeItem("booking");
    sessionStorage.removeItem("cake");
    sessionStorage.removeItem("parents");
    setFormValues({ booking: {}, cake: {}, parents: {} });
    setGetData({ url: getFormDataUrl });
  }, [setFormValues, setGetData]);

  const updateBookingRequest = useCallback(() => {
    setPostData({
      url: null,
      query: null,
    });
    const PerrorLabels = document.querySelectorAll(".error-label");
    for (let label of PerrorLabels) {
      label.remove();
    }
    setPostData({
      url: updateBookingUrl,
      query: formValues, //collectFormData(),
    });
  }, [setPostData, formValues]);

  const sendBookingRequest = useCallback(() => {
    setPostData({
      url: createBookingUrl,
      query: null,
    });
    const PerrorLabels = document.querySelectorAll(".error-label");
    for (let label of PerrorLabels) {
      label.remove();
    }
    setPostData({
      url: createBookingUrl,
      query: collectFormData(),
    });
  }, [setPostData, collectFormData]);

  useEffect(() => {
    let resetDict = { booking: {}, cake: {}, parents: {} };

    if (location.state) {
      const localBookingData = sessionStorage.getItem("booking");
      const localCakeData = sessionStorage.getItem("cake");
      const localParentsData = sessionStorage.getItem("parents");
      if (localBookingData !== null) {
        const bookingDataDict = JSON.parse(localBookingData);
        resetDict.booking = bookingDataDict;
      }
      if (localCakeData !== null) {
        const cakeDataDict = JSON.parse(localCakeData);
        resetDict.cake = cakeDataDict;
      }
      if (localParentsData !== null) {
        const parentsDataDict = JSON.parse(localParentsData);
        resetDict.parents = parentsDataDict;
      }

      if (location.state.date) resetDict.booking["data"] = location.state.date;
    }

    setFormValues(resetDict);
    return () => {
      sessionStorage.booking = "{}";
      sessionStorage.cake = "{}";
      sessionStorage.parents = "{}";
    };
  }, []);

  useEffect(() => {
    if (Gdata) {
      if (Gdata.regalo) {
        setInitialSelectCustomerData({
          ...baseInitalFormDataRef.current,
          regalo_extra: Gdata.regalo,
          tipo_compleanno: Gdata.tipi_compleanno,
        });

        setInitialSelectCakeData({
          cialda: [
            { text: "no", value: false },
            { text: "si", value: true },
          ],
          bagna_alcolica: [
            { text: "no", value: false },
            { text: "si", value: true },
          ],
          tipo: Gdata.tipo_dolce,
          farcitura: Gdata.farcitura,
          copertura: Gdata.coperture,
          base: Gdata.basi,
          forma: Gdata.forme,
        });
      }
    }
  }, [
    Gdata,
    Gloading,
    Gerror,
    setInitialSelectCustomerData,
    baseInitalFormDataRef,
    setInitialSelectCakeData,
  ]);

  useEffect(() => {
    if (location.state) {
      if (location.state.type) {
        setPostData({
          url: new URL(`${getEventFormDataUrl}${location.state.type}/`),
          query: { id: location.state.id },
        });
      }
    }
  }, [location, setFormValues, setPostData]);

  useEffect(() => {
    if (Perror !== undefined && Perror !== null) {
      if (oldErrorRef.current !== Perror) {
        oldErrorRef.current = Perror;
        const ids = Object.keys(Perror);
        for (let id of ids) {
          showError(id, Perror[id]);
        }
      }
    } else if (Pdata !== null) {
      if (!postData?.url?.href?.includes("get_event_form_detail")) {
        if (Pdata.id !== undefined) {
          setEventId(Pdata.id);
          setShowDetailsModal(true);
          clearForm();
        } else if (Pdata.update) {
          //history.push('/')
          setShowEmailModal(true);
        } else if (Pdata.success === "Email sended") {
          history.push("/");
        }
      } else {
        setFormValues(Pdata);
      }
    }
  }, [
    postData?.url,
    Pdata,
    Ploading,
    Perror,
    setShowDetailsModal,
    setFormValues,
    setEventId,
    oldErrorRef,
    clearForm,
    setShowParentsForm,
  ]);

  useEffect(() => {
    if (
      formValues.booking.dolce === true ||
      formValues.booking.dolce === "true"
    )
      setShowCakeForm(true);
    else setShowCakeForm(false);

    if (
      formValues.booking.aperitivo_genitori === true ||
      formValues.booking.aperitivo_genitori === "true"
    )
      setShowParentsForm(true);
    else setShowParentsForm(false);
  }, [formValues]);

  const renderFormMask = useCallback(() => {
    if (Gdata && !isDictUndef(Gdata.regalo)) {
      return (
        <>
          <FormMask
            title="Dettagli cliente"
            initialFormData={initialCustomerData}
            selects={initialSelectCustomerData}
            formValues={formValues.booking}
            setFormValues={setFormValues}
            setFormValuesByEvent={setFormValuesByEvent}
            setFormNomiFesteggiati={setFormNomiFesteggiati}
            setFormEtaFesteggiati={setFormEtaFesteggiati}
            group="booking"
            show={true}
          />

          <FormMask
            title="Dettagli dolce"
            selects={initialSelectCakeData}
            initialFormData={initialCakeData}
            formValues={formValues.cake}
            setFormValues={setFormValues}
            setFormValuesByEvent={setFormValuesByEvent}
            group="cake"
            show={showCakeForm}
          />

          <FormMask
            title="Dettagli genitori"
            initialFormData={initialParentData}
            formValues={formValues.parents}
            setFormValues={setFormValues}
            setFormValuesByEvent={setFormValuesByEvent}
            group="parents"
            show={showParentsForm}
          />
        </>
      );
    }
  }, [
    setFormEtaFesteggiati,
    initialSelectCustomerData,
    Gdata,
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormNomiFesteggiati,
    initialSelectCakeData,
    showCakeForm,
    showParentsForm,
  ]);

  const renderButtons = useCallback(() => {
    if (location.state) {
      if (!location.state.date)
        return (
          <Button
            text="Salva"
            onclick={() => {
              updateBookingRequest();
            }}
            color="primary"
          />
        );
    }
    return (
      <>
        <Button
          text="Continua"
          onclick={() => {
            sendBookingRequest();
          }}
          color="primary"
        />
        <Button
          text="Svuota campi"
          onclick={() => {
            clearForm();
          }}
          color="secondary"
        />
      </>
    );
  }, [location, clearForm, sendBookingRequest, updateBookingRequest]);

  return (
    <div className="prenotazioni-page">
      <AuthCheck />

      {showEmailModal && (
        <ConfirmModal
          title="Vuoi inviare una email al cliente?"
          text="Se hai modificato l'orario o il metodo di pagamanto e vuoi informare il cliente, conferma l'invio dell' email, altrimenti continua."
          onSuccess={() => {
            notifyUpdateHandler();
          }}
          onCancel={() => {
            history.push("/");
          }}
          successText="Invia"
          cancelText="Continua"
          colorCancel="green"
        />
      )}

      {(Gloading || Ploading) && <Loader />}

      {showDetailsModal ? (
        <NewBookingModalContainer
          showHandler={(action) => {
            showModalHandler(action);
            history.push("/");
          }}
          eventId={eventId}
          tipo="birthday"
        />
      ) : null}

      {renderFormMask()}

      <div className="prenotazioni-btn-wrap">{renderButtons()}</div>
    </div>
  );
};

export default Prenotazioni;
