import "./ModalActionButtons.css";
import Button from "../views/Button";

const ModalActionButtons = ({
  btnAction,
  btnText,
  btn1Text,
  btn1Callback,
  btn2Text,
  btn2Callback,
  btn3Text,
  btn3Callback,
}) => {
  return (
    <div className="modal-btn-action-wrap">
      <Button
        onclick={() => {
          if (btnAction) {
            btnAction();
          }
        }}
        text={btnText}
      />
      {btn1Text && (
        <Button
          onclick={() => {
            btn1Callback();
          }}
          color="green"
          text={btn1Text}
        />
      )}
      {btn2Text && (
        <Button
          color="secondary"
          onclick={() => {
            btn2Callback();
          }}
          text={btn2Text}
        />
      )}
      {btn3Text && (
        <Button
          color="yellow"
          onclick={() => {
            btn3Callback();
          }}
          text={btn3Text}
        />
      )}
    </div>
  );
};

export default ModalActionButtons;
